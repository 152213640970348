import React, { Component } from "react";
//import logo from './logo.svg';
import FrontType from "./FrontType";
import "./App.css";

class App extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    {/* <img src={logo} className="App-logo" alt="logo" /> */}
                    <p className="logo">
                        <img className="App-logo" alt="logo" src="/lazee.png" />
                    </p>
                    <div className="App-scroller">
                        <FrontType
                            loop={false}
                            speed={50}
                        />
                    </div>
                    {/* <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                        Contact me
                    </a> */}
                </header>
            </div>
        );
    }
}

export default App;
