import React from "react";
import TypeIt from "typeit";
import "./App.css";

class FrontType extends React.Component {
    componentDidMount() {
        new TypeIt(this.el, this.props)
            .type("Hi, I'm Jakob Vad Nielsen")
            .pause(1000)
            .exec(async () => {
                await new Promise((resolve, reject) => {
                    return resolve();
                });
            })
            .delete()
            .type("I work as a fullstack developer with main focus on devops")
            .pause(1000)
            .delete(6)
            .pause(1000)
            .type('<a class="App-link" href="https://en.wikipedia.org/wiki/Site_Reliability_Engineering">SRE</a>')
            .pause(1000)
            .type(", system design and coding.")
            .pause(2000)
            .empty()
            .pause(1000)
            .empty()
            .type(" I ❤️ anything that is tech and design related.")
            .pause(1000)
            .empty()
            .type('You can find me on Twitter as <a class="App-link" href="https://twitter.com/jakobvad">jakobvad</a> and on LinkedIn as <a class="App-link" href="https://www.linkedin.com/in/jakobvadnielsen/">jakobvadnielsen</a>.')
            //.pause(1000)
            //.type('<br/><br/>Click <a class="App-link"  href="">here</a> to learn more about me.')
            .go();
    }

    render() {
        return (
            <span
                ref={el => {
                    this.el = el;
                }}
            >
                {this.props.children}
            </span>
        );
    }
}

export default FrontType;
